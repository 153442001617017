<script>
import BottomNav from '@/components/Themes/dayonedayone/BottomNav';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import dayjs from 'dayjs';

export default {
  components: {
    BottomNav,
  },
  data() {
    return {
      cdnUrl: process.env.VUE_APP_CDN_URL,
      legacyCdnUrl: process.env.VUE_APP_LEGACY_CDN_URL,
      tabs: null,
      isLoading: false,
      success: '',
      error: '',
      dialogShare: false,
      dialogRedeem: false,
      showService: false,
      loyaltyReward: null,
      services: [],
    };
  },
  computed: {
    ...mapState('customer', ['customer']),
    ...mapState('loyalty', {
      rewards: (state) => state.rewards,
      vouchers: (state) => state.vouchers,
      loyaltyCustomer: (state) => state.customer,
      pointBalance: (state) => state.pointBalance,
    }),
    ...mapState('merchant', {
      profile: (state) => state.profile,
    }),
    ...mapGetters('auth', {
      isLoggedIn: ['isLoggedIn'],
    }),
    ...mapGetters('cart', {
      serviceIcons: ['getServiceIcons'],
    }),
  },
  methods: {
    ...mapActions('data', ['createDataAnalyticEvent']),
    ...mapActions('loyalty', [
      'getLoyaltyRewards',
      'getLoyaltyVouchers',
      'getLoyaltyPointBalance',
      'redeemLoyaltyReward'
    ]),
    ...mapMutations('cart', ['SET_SERVICE', 'SET_VOUCHER']),
    formatDate(date) {
      return dayjs(date).format('DD MMM YYYY');
    },
    getVoucherPhoto(voucher) {
      if (voucher.type !== 'product') {
        return voucher.cover ? `${this.cdnUrl}/${voucher.cover}` : require(`@/assets/images/food-item-default.webp`);
      }

      return voucher.item.photo_s3 ? `${this.cdnUrl}/${voucher.item.photo_s3}` : `${this.legacyCdnUrl}/upload/${voucher.item.photo}`;
    },
    ShareThisonFB() {
      this.$gtag.event('share-facebook', {
        'event_category': 'engagement',
        'event_label': 'Share Facebook',
        'event_value': this.profile.restaurant_hostname,
      });

      this.createDataAnalyticEvent({
        event: 'share-social',
        data: {
          hqMerchantId: this.profile.hq_merchant_id,
          merchantId: this.profile.merchant_id,
          refTable: this.loyaltyCustomer ? 'loyalty_clients' : (this.customer ? 'client' : 'uuid'),
          refId: this.loyaltyCustomer?.id ?? this.customer?.phoneNo ?? localStorage.uuid,
          category: 'engagement',
          label: 'From',
          value: 'Facebook',
        },
      });

      window.open(
        `https://www.facebook.com/sharer/sharer.php?u=https://${this.profile.restaurant_hostname}`,
        '_blank'
      );
    },
    ShareThisonTwitter() {
      this.$gtag.event('share-twitter', {
        'event_category': 'engagement',
        'event_label': 'Share Twitter',
        'event_value': this.profile.restaurant_hostname,
      });

      this.createDataAnalyticEvent({
        event: 'share-social',
        data: {
          hqMerchantId: this.profile.hq_merchant_id,
          merchantId: this.profile.merchant_id,
          refTable: this.loyaltyCustomer ? 'loyalty_clients' : (this.customer ? 'client' : 'uuid'),
          refId: this.loyaltyCustomer?.id ?? this.customer?.phoneNo ?? localStorage.uuid,
          category: 'engagement',
          label: 'From',
          value: 'Twitter',
        },
      });

      window.open(`https://twitter.com/intent/tweet`, '_blank');
    },
    ShareThisonWhatsapp() {
      this.$gtag.event('share-whatsapp', {
        'event_category': 'engagement',
        'event_label': 'Share WhatsApp',
        'event_value': this.profile.restaurant_hostname,
      });

      this.createDataAnalyticEvent({
        event: 'share-social',
        data: {
          hqMerchantId: this.profile.hq_merchant_id,
          merchantId: this.profile.merchant_id,
          refTable: this.loyaltyCustomer ? 'loyalty_clients' : (this.customer ? 'client' : 'uuid'),
          refId: this.loyaltyCustomer?.id ?? this.customer?.phoneNo ?? localStorage.uuid,
          category: 'engagement',
          label: 'From',
          value: 'WhatsApp',
        },
      });

      window.open(
        `https://api.whatsapp.com/send?text=${encodeURIComponent('Comes and grab this interesting voucher from')} https://${this.profile.restaurant_hostname}`,
        '_blank'
      );
    },
    requestRedeemLoyaltyReward(loyaltyReward) {
      if (!loyaltyReward.dinein && this.pointBalance >= loyaltyReward.points) {
        this.$gtag.event('redeem-loyalty-reward', {
          event_category: 'interaction',
          event_label: `Redeem Loyalty Reward (${loyaltyReward.code})`,
          event_value: loyaltyReward,
        });

        this.createDataAnalyticEvent({
          event: 'redeem-loyalty-reward',
          data: {
            hqMerchantId: this.profile.hq_merchant_id,
            merchantId: this.profile.merchant_id,
            refTable: 'loyalty-rewards',
            refId: loyaltyReward.id,
            category: 'interaction',
          },
        });

        this.loyaltyReward = loyaltyReward;
        this.dialogRedeem = true;
      }
    },
    async confirmRedeemLoyaltyReward() {
      this.$gtag.event('confirm-redeem-loyalty-reward', {
        event_category: 'engagement',
        event_label: `Confirm Redeem Loyalty Reward (${this.loyaltyReward.code})`,
        event_value: this.loyaltyReward,
      });

      try {
        await this.redeemLoyaltyReward({
          id: this.loyaltyReward.id,
          data: {},
        });

        this.createDataAnalyticEvent({
          event: 'redeem-loyalty-reward-success',
          data: {
            hqMerchantId: this.profile.hq_merchant_id,
            merchantId: this.profile.merchant_id,
            refTable: 'loyalty-rewards',
            refId: this.loyaltyReward.id,
            category: 'engagement',
            label: 'Loyalty Client ID',
            value: this.loyaltyCustomer?.id,
          },
        });

        this.loyaltyReward = null;
        this.dialogRedeem = false;

        // Get newly redeemed voucher
        this.getLoyaltyVouchers({});
        this.tabs = 1;
        this.success = 'Thank you, you have successfully redeemed your reward!';
        setTimeout(() => this.success = '', 3000);
      } catch(e) {
        this.createDataAnalyticEvent({
          event: 'redeem-loyalty-reward-error',
          data: {
            hqMerchantId: this.profile.hq_merchant_id,
            merchantId: this.profile.merchant_id,
            refTable: 'loyalty-rewards',
            refId: this.loyaltyReward.id,
            category: 'experience',
          },
        });

        console.log(e);
        this.error = 'Something went wrong, please try again later.';
        setTimeout(() => this.error = '', 5000);
      }
    },
    apply(voucher) {
      this.$gtag.event('apply-voucher', {
        event_category: 'engagement',
        event_label: `Apply Voucher (${voucher?.restaurantName})`,
        event_value: voucher,
      });

      this.SET_VOUCHER({
        id: voucher.id,
        code: voucher.code,
        type: voucher.type,
        amount: voucher.amount,
        min_purchase: voucher.minPurchase,
        item: voucher.item,
      });

      this.createDataAnalyticEvent({
        event: 'apply-voucher-success',
        data: {
          hqMerchantId: this.profile.hq_merchant_id,
          merchantId: this.profile.merchant_id,
          refTable: 'voucher-new',
          refId: voucher.id,
          category: 'engagement',
        },
      });

      this.showServiceSheet(voucher?.delivery, voucher?.pickup, voucher?.dinein);
    },
    showServiceSheet(delivery, pickup, dinein) {
      this.services = [];

      if (delivery) {
        this.services.push({
          label: 'Delivery',
          value: 'delivery',
        });
      }

      if (pickup) {
        this.services.push({
          label: 'Pick Up',
          value: 'pickup',
        });
      }

      if (dinein) {
        this.services.push({
          label: 'Dine In',
          value: 'dinein',
        });
      }

      this.showService = true;
    },
    selectService(label, value) {
      this.$gtag.event('select-service', {
        event_category: 'navigation',
        event_label: label,
        event_value: value,
      });

      this.SET_SERVICE(value);
      this.showService = false;

      if (value == 'dinein') {
        this.$router.push({ name: 'scan' }).catch(() => {});
      } else {
        this.$router.push({ name: 'menu' }).catch(() => {});
      }
    },
  },
  async mounted() {
    // Get Loyalty Point Balance
    this.getLoyaltyPointBalance({});

    // Get Loyalty Rewards
    this.getLoyaltyRewards({
      hqMerchantId: this.profile.hq_merchant_id,
      merchantId: this.profile.merchant_id,
      self: 1,
    });

    // Get Loyalty Vouchers
    this.getLoyaltyVouchers({});

    if (this.$route.query?.default) {
      this.tabs = +this.$route.query?.default;
    }
  },
  watch: {
    tabs: function (val) {
      this.createDataAnalyticEvent({
        event: 'switch-tab',
        data: {
          hqMerchantId: this.profile.hq_merchant_id,
          merchantId: this.profile.merchant_id,
          refTable: this.loyaltyCustomer ? 'loyalty_clients' : (this.customer ? 'client' : 'uuid'),
          refId: this.loyaltyCustomer?.id ?? this.customer?.phoneNo ?? localStorage.uuid,
          category: 'navigation',
          label: 'Tab',
          value: val,
        },
      });
    },
  },
}
</script>
<template>
  <v-card class="card-tabs-reward">
    <v-alert
      v-if="success"
      class="alert text-body-2 p-2"
      type="success"
      transition="fade-transition"
      dense
      >{{ success }}</v-alert
    >
    <v-alert
      v-if="error"
      class="alert text-body-2 p-2"
      type="error"
      transition="fade-transition"
      dense
      >{{ error }}</v-alert
    >
    <v-tabs
      v-model="tabs"
      active-class="tab-reward-active"
      background-color="#fcf7f4"
      fixed-tabs
      dark
      height="36"
      slider-color="#e1cfc7"
    >
      <v-tab v-if="loyaltyCustomer">
        Reward Catalogue
      </v-tab>
      <v-tab>
        My Rewards
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tabs">
      <v-tab-item v-if="loyaltyCustomer">
        <v-sheet 
          class="reward-list-sheet mx-auto pt-3 px-4"
          elevation="0"
        >
          <v-sheet 
            ref="reward-title-sheet"
            class="reward-title-sheet"
            elevation="0"
          >
            <v-row>
              <v-col class="pb-0">
                <v-img
                  contain
                  class="ml-4 p-2"
                  position="top center"
                  width="80%"
                  src="@/assets/dayonedayone/images/loyalty-reward-title.svg"
                ></v-img>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="p-0 pl-8" cols="4">
                <v-chip
                  small
                  class="chip-title"
                  >Current Points</v-chip
                >
                <h3 class="font-weight-6 mt-1">{{ pointBalance }}<span class="text-caption"> pts</span></h3>
              </v-col>
              <v-col class="p-0 pr-4" cols="8">
                <v-img
                  contain
                  class="mr-1"
                  position="top right"
                  height="120"
                  src="@/assets/dayonedayone/images/loyalty-reward-title-img.webp"
                ></v-img>
              </v-col>
            </v-row>
          </v-sheet>
          <v-sheet 
            ref="reward-sheet"
            class="reward-sheet mt-6 p-2"
            color="transparent"
            elevation="0"
          >
            <v-row no-gutters>
              <v-col
                class="mb-4"
                cols="6"
                :key="`reward-${reward.id}`"
                v-for="(reward, index) in rewards"
              >
                <v-card
                  color="#efe6df"
                  class="rounded-xl p-2"
                  :class="{
                    'ml-2': index % 2 == 1,
                    'mr-2': index % 2 == 0,
                  }"
                  elevation="0"
                  @click="requestRedeemLoyaltyReward(reward)"
                >
                  <v-img
                    class="rounded-xl"
                    position="center"
                    height="150"
                    :src="`${cdnUrl}/${reward.cover}`"
                  ></v-img>
                  <v-card-title class="reward-code text-caption font-weight-bold p-2">{{ reward.code }}</v-card-title>
                  <v-card-subtitle class="text-center mt-1 py-0 px-2">
                    <!-- <v-chip
                      small
                      class="mr-1 my-1 chip-service"
                      v-if="reward.delivery"
                      >Delivery</v-chip
                    >
                    <v-chip
                      small
                      class="mr-1 my-1 chip-service"
                      v-if="reward.pickup"
                      >Pickup</v-chip
                    >
                    <v-chip
                      small
                      class="mr-1 my-1 chip-service"
                      v-if="reward.dinein"
                      >Dine In</v-chip
                    > -->
                    <span
                      class="font-weight-bold"
                      style="font-size:10px; color:#757575"
                      v-if="reward.dinein"
                    >
                      * Redeem at Cashier *
                    </span>
                  </v-card-subtitle>
                  <v-card-actions class="p-0">
                    <v-row class="px-5">
                      <v-col cols="12" class="text-center p-0">
                        <span class="text-caption tertiary--text font-weight-bold">
                          {{ reward.points }} pts
                        </span>
                      </v-col>
                      <!-- <v-col cols="5" class="text-right p-0">
                        <v-btn
                          class="text-caption text-capitalize justify-content-end font-weight-bold px-0"
                          color="tertiary"
                          height="24"
                          style="min-width:auto;"
                          :disabled="pointBalance < reward.points"
                          text
                        >
                          Redeem
                        </v-btn>
                      </v-col> -->
                    </v-row>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-sheet>
        </v-sheet>
        <v-dialog
          v-model="dialogRedeem"
          max-width="290"
          v-if="loyaltyReward"
        >
          <v-card color="#efe6df">
            <v-card-title class="text-h6 font-weight-6 mb-1" style="word-break: normal;">
              {{ loyaltyReward.code }}
            </v-card-title>
            <v-card-text class="pb-2">
              <v-img
                class="rounded-xl"
                position="center"
                width="100%"
                :src="`${cdnUrl}/${loyaltyReward.cover}`"
              ></v-img>
              <div class="text-body-2 font-weight-6 mt-3">
                Are you sure want to redeem this voucher with <span class="text-body-1 tertiary--text font-weight-6">{{ loyaltyReward.points }} points</span>?
              </div>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                class="text-caption font-weight-5"
                color="disabled"
                text
                @click="dialogRedeem = false"
              >
                No
              </v-btn>

              <v-btn
                class="font-weight-bold"
                color="success darken-2"
                text
                @click="confirmRedeemLoyaltyReward()"
              >
                Yes!
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-tab-item>
      <v-tab-item>
        <v-sheet 
          class="voucher-list-sheet mx-auto pt-3 px-4"
          elevation="0"
        >
          <v-sheet 
            ref="voucher-title-sheet"
            class="voucher-title-sheet"
            elevation="0"
          >
            <v-row>
              <v-col class="pb-0">
                <v-img
                  contain
                  class="ml-4 p-2"
                  position="top center"
                  width="80%"
                  src="@/assets/dayonedayone/images/loyalty-voucher-title.svg"
                ></v-img>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="p-0 pl-8" cols="4">
                <!-- <v-chip
                  small
                  class="chip-title"
                  >Current Points</v-chip
                >
                <h3 class="font-weight-6 mt-1">{{ pointBalance }}<span class="text-caption"> pts</span></h3> -->
              </v-col>
              <v-col class="p-0 pr-4" cols="8">
                <v-img
                  contain
                  class="mr-1"
                  position="top right"
                  height="120"
                  src="@/assets/dayonedayone/images/loyalty-voucher-title-img.webp"
                ></v-img>
              </v-col>
            </v-row>
          </v-sheet>
          <v-sheet 
            ref="voucher-sheet"
            class="voucher-sheet mt-6 p-2"
            color="transparent"
            elevation="0"
          >
            <v-row no-gutters>
              <v-col
                class="mb-4"
                cols="6"
                :key="`voucher-${voucher.id}`"
                v-for="(voucher, index) in vouchers"
              >
                <v-card
                  color="#efe6df"
                  class="rounded-xl p-2"
                  :class="{
                    'ml-2': index % 2 == 1,
                    'mr-2': index % 2 == 0,
                  }"
                  elevation="0"
                >
                  <v-img
                    class="rounded-xl"
                    position="center"
                    height="150"
                    :src="getVoucherPhoto(voucher)"
                  ></v-img>
                  <v-card-title class="voucher-code text-caption font-weight-bold p-2">{{ voucher.code }}</v-card-title>
                  <v-card-subtitle class="mt-1 py-0 px-2">
                    <v-chip
                      small
                      class="mr-1 mb-1 chip-service"
                      v-if="voucher.delivery"
                      >Delivery</v-chip
                    >
                    <v-chip
                      small
                      class="mr-1 mb-1 chip-service"
                      v-if="voucher.pickup"
                      >Pickup</v-chip
                    >
                    <v-chip
                      small
                      class="mr-1 mb-1 chip-service"
                      v-if="voucher.dinein"
                      >Dine In</v-chip
                    >
                  </v-card-subtitle>
                  <v-card-actions class="p-0">
                    <v-row class="px-5">
                      <v-col cols="5" class="text-left p-0">
                        <v-btn
                          class="text-caption text-capitalize font-weight-bold px-0"
                          color="tertiary"
                          height="24"
                          style="min-width:auto;"
                          text
                          @click="apply(voucher)"
                          v-if="voucher.delivery || voucher.pickup"
                        >
                          Use
                        </v-btn>
                      </v-col>
                      <v-col cols="7" class="text-right p-0">
                        <v-btn
                          class="text-caption text-capitalize font-weight-bold px-0"
                          color="tertiary"
                          height="24"
                          style="min-width:auto;"
                          text
                          @click="dialogShare = true"
                        >
                          Share
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-sheet>
        </v-sheet>
        <v-dialog v-model="dialogShare" max-width="290">
          <v-card>
            <v-card-title class="headline"> Share </v-card-title>
            <v-card-text>Let your friends know about us by sharing</v-card-text>
            <v-card-text>
              <div class="d-flex justify-content-around">
                <v-icon color="#3b5998" @click="ShareThisonFB">mdi-facebook</v-icon>
                <v-icon color="#1da1f2" @click="ShareThisonTwitter">mdi-twitter</v-icon>
                <v-icon color="#25d366" @click="ShareThisonWhatsapp">mdi-whatsapp</v-icon>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="grey lighten-1" small plain text @click="dialogShare = false"> Close </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-tab-item>
    </v-tabs-items>
    <v-bottom-sheet v-model="showService">
      <v-card min-height="30vh" max-height="60vh" class="overflow-y-auto p-0">
        <v-card-title class="text-button font-weight-6 justify-center">
          <v-icon color="primary" class="mr-2">mdi-food</v-icon>
          Services
        </v-card-title>
        <v-card height="8" color="#f3f3f3"></v-card>
        <v-card flat color="#f3f3f3" class="pb-2 px-0">
          <v-list color="#ffffff">
            <v-list-item
              v-for="service in services"
              :key="service.value"
              @click="selectService(service.label, service.value)"
            >
              <v-list-item-avatar class="my-0">
                <v-icon color="primary">{{
                  serviceIcons[service.value]
                }}</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="text-caption font-weight-6">{{
                  service.label
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-card>
    </v-bottom-sheet>
    <BottomNav :fixed="true"/>
  </v-card>
</template>
<style scoped>
.card-tabs-reward {
  background-color: #fcf7f4;
  min-height: 100dvh;
  padding: 0px 4px;
}

.card-tabs-reward /deep/ .alert {
  position: fixed;
  z-index: 999;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
}

.card-tabs-reward /deep/ .v-tab {
  font-size: 10px;
  font-weight: bold;
  border-radius: 16px 16px 0px 0px;
  color: #000000 !important;
}

.tab-reward-active {
  background-color: #e1cfc7;
}

.v-tabs-items {
  background-color: #fcf7f4;
}

.reward-list-sheet {
  background-color: #e1cfc7;
  border-top-right-radius: 16px;
  width: 100%;
  min-height: 100dvh;
  padding-bottom: 45px;
}

.voucher-list-sheet {
  background-color: #e1cfc7;
  border-top-left-radius: 16px;
  width: 100%;
  min-height: 100dvh;
  padding-bottom: 45px;
}

.reward-list-sheet .reward-title-sheet,
.voucher-list-sheet .voucher-title-sheet {
  background-color: #fcf7f4;
  border-radius: 16px;
  height: 180px;
}

.reward-list-sheet /deep/ .reward-slider-item.v-card,
.voucher-list-sheet /deep/ .voucher-slider-item.v-card {
  width: 220px;
}

.reward-list-sheet /deep/ .reward-slider-item.v-card > .v-card__title,
.voucher-list-sheet /deep/ .voucher-slider-item.v-card > .v-card__title {
  background-color: #f7efe8;
}

.reward-list-sheet /deep/ .reward-slider-item.v-card > .v-card__text,
.voucher-list-sheet /deep/ .voucher-slider-item.v-card > .v-card__text {
  background-color: #efe6df;
  border-radius: 4px;
}

.reward-list-sheet /deep/ .theme--light.v-text-field--solo > .v-input__control > .v-input__slot,
.voucher-list-sheet /deep/ .theme--light.v-text-field--solo > .v-input__control > .v-input__slot {
  border: none !important;
  background: #f0d2b7 !important;
  z-index: 1 !important;
}

.reward-code, .voucher-code {
  height: 4.25rem;
  align-items: start;
}

.chip-title {
  font-size: 6px !important;
  font-weight: 600;
  height: 18px;
  border-radius: 4px;
  color: #ffffff !important;
  background-color: var(--v-tertiary) !important;
}

.chip-service {
  font-size: 9px !important;
  font-weight: 600;
  height: 18px;
  border-radius: 4px;
  color: #ffffff !important;
  background-color: var(--v-tertiary) !important;
}

@media screen and (min-width: 1024px) {
  .card-tabs-reward .alert {
    width: 374px !important;
  }
  
  .reward-list-sheet /deep/ .reward-slider-item.v-card,
  .voucher-list-sheet /deep/ .voucher-slider-item.v-card {
    width: 180px;
  }
}
</style>